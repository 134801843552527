<template>
  <div class="bg-fromcode sizeMinAllView">
    <p
      class="text-xs text-left font-dinpro font-normal text-white mb-3.5 ml-16 md:text-lg"
    >
      Ingresar código
    </p>
    <div
      class="grid grid-cols-4 place-items-center place-content-around container-inputcode mb-8 md:gap-x-10 md:mb-16"
    >
      <input
        id="code-1"
        name="code1"
        type="number"
        class="input-test2 code w-9 h-9 md:w-16 md:h-14"
        v-model="formData.code1"
        @keyup="moveToNextInpuField()"
        maxlength="1"
      />
      <input
        id="code-2"
        name="code2"
        type="number"
        class="input-test2 code w-9 h-9 md:w-16 md:h-14"
        v-model="formData.code2"
        @keyup="moveToNextInpuField()"
        maxlength="1"
      />
      <input
        id="code-3"
        name="code3"
        type="number"
        class="input-test2 code w-9 h-9 md:w-16 md:h-14"
        v-model="formData.code3"
        @keyup="moveToNextInpuField()"
        maxlength="1"
      />
      <input
        id="code-4"
        name="code4"
        type="number"
        class="input-test2 code w-9 h-9 md:w-16 md:h-14"
        v-model="formData.code4"
        @keyup="moveToNextInpuField()"
        maxlength="1"
      />
    </div>
    <div class="ml-16 mr-14">
      <div class="mb-10">
        <div
          class="mb-8 bg-transparent border border-agrogo-yellow-1 h-8 rounded flex items-center justify-center md:h-10 md:rounded-xl"
        >
          <span
            class="font-nexa font-normal text-agrogo-yellow-1 text-xs md:text-base"
            >Tu código vence en: {{ seconds }} seg</span
          >
        </div>
        <button
          class="btn-login flex justify-center items-center md:h-10"
          @click.prevent="validateCode()"
          :disabled="isBusy"
        >
          <div class="loader" v-if="isBusy" />
          <span v-else>Continuar</span>
        </button>
        <div
          class="mt-4 text-left bg-agrogo-yellow-1 p-2"
          v-if="errors.length > 0"
        >
          <ul class="ul">
            <li
              class="text-agrogo-green-2 font-nexa text-sm mb-1"
              v-for="(item, index) in errors"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["validateCode", "resendCode"],
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    codeReset: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        code1: "",
        code2: "",
        code3: "",
        code4: "",
      },
      seconds: 60,
    };
  },
  watch: {
    codeReset(newData) {
      const me = this;
      if (newData) {
        me.formData.code1 = "";
        me.formData.code2 = "";
        me.formData.code3 = "";
        me.formData.code4 = "";
      }
    },
  },
  methods: {
    validateCode() {
      const me = this;
      const codeA = `${me.formData.code1}${me.formData.code2}${me.formData.code3}${me.formData.code4}`;
      me.$emit("validateCode", codeA);
    },
    moveToNextInpuField() {
      const inputFields = document.querySelectorAll("input");
      for (const [i, inputElement] of inputFields.entries()) {
        if (inputElement.value.length === 1 && i !== 4) {
          inputFields[i + 1].focus();
          inputFields[i].classList.add("active-input");
        }
        if (inputElement.value.length === 1 && i === 4) {
          inputElement.parentElement.nextElementSibling.focus();
        }
      }
    },
    timer() {
      const me = this;
      me.interval = setInterval(() => {
        me.counter();
      }, 1000);
    },
    counter() {
      const me = this;
      const message =
        "El código ha vencido, por favor solicite un nuevo código de verificación.";
      me.seconds == 0 ? me.$emit("resendCode", message) : me.seconds--;
    },
  },
  mounted() {
    const me = this;
    me.timer();
  },
};
</script>

<style lang="css" scoped>
.code {
  padding-right: 0.5rem !important;
  font-size: 20px !important;
  text-align: center !important;
}
@media (min-width: 768px) {
  .code {
    padding-right: 0.5rem !important;
    font-size: 36px !important;
    text-align: center !important;
  }
}
.active-input {
  border: 1px solid #f5ce3e;
}
</style>
