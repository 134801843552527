<template>
  <p
    class="text-xs font-dinpro font-normal text-white mb-3.5 ml-16 mr-10 md:text-lg md:ml-16 md:mr-16"
  >
    {{
      telephone
        ? `Te enviaremos un código de verificación vía WhatsApp, al siguiente numero.`
        : `Te enviaremos un código de verificación vía WhatsApp, por favor ingresa tu número.`
    }}
  </p>
  <div>
    <div class="flex mx-14">
      <div class="relative w-2/5 md:w-3/12 mr-2">
        <img :src="img" class="img-code" />
        <v-select
          :options="codes"
          v-model="selected"
          :reduce="(option) => option.id"
          class="style-chooser"
          label="phone_code"
          :disabled="telephone ? true : false"
        >
          <template #option="{ phone_code, path_img_iso_large, name }">
            <div class="flex items-center justify-between">
              <div class="flex">
                <img :src="path_img_iso_large" :alt="phone_code" class="mr-2" />
                <div class="font-medium">{{ phone_code }}</div>
              </div>
              <div class="font-medium">
                <div v-if="name.length > 20">{{ name.slice(0, 20) }}..</div>
                <div v-else>{{ name }}</div>
              </div>
            </div>
          </template>
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
      <div class="relative w-3/5 md:w-9/12">
        <div
          class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
        >
          <span class="icon-telefono text-agrogo-black-1 text-lg"></span>
        </div>
        <input
          v-model="v$.formData.telephone.$model"
          @blur="v$.formData.telephone.$touch()"
          id="telephone"
          name="telephone"
          type="text"
          class="input-login"
          :disabled="telephone ? true : false"
          :class="{ 'error-form': v$.formData.telephone.$error }"
        />
      </div>
    </div>
    <p
      class="text-xs text-agrogo-yellow-2 font-nexa block mb-2 text-left mt-2 mx-14"
      v-if="v$.formData.telephone.$error"
    >
      {{ messageValidation(v$.formData.telephone) }}
    </p>
    <p
      class="text-xs text-agrogo-yellow-2 font-nexa block mb-2 text-left mt-2 mx-14"
      v-else
    >
      &nbsp;
    </p>
  </div>
  <div class="grid grid-cols-1 gap-x-2 mb-5 md:gap-x-5 md:mb-9 mx-14">
    <div>
      <button
        :disabled="v$.$invalid || isBusy"
        type="button"
        class="btn-login"
        @click.prevent="send"
      >
        <div class="loader" v-if="isBusy" />
        <span v-else>Solicitar código</span>
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import useVuelidate from "@vuelidate/core";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";

export default {
  inject: ["provider"],
  emits: ["send"],
  mixins: [validationMixin, loginRegisterMixin],
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    telephone: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      repository: me.provider.authRepository,
      v$: useVuelidate(),
      codes: [],
      formData: {
        telephone: me.telephone ? me.telephone : "",
        code_area_id: 1,
      },
    };
  },
  validations() {
    return {
      formData: {
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(15),
        },
      },
    };
  },
  methods: {
    send() {
      const me = this;
      if (!me.v$.$invalid) {
        me.$emit("send", me.formData);
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadCodeArea();
  },
};
</script>
